import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import * as Types from './type.d';

const server = DOMAIN_SYSTEM_ENUM.tiangong;

/**
 * 确定选图
 */
export function confirmImage(data: Types.IDyProductImageSelectionReq) {
  return http.post<Types.IDyProductImageSelectionRes>({
    url: '/moken-portal/web/v1/dy-product/image-selection',
    loading: true,
    server,
    data,
  });
}

/**
 * 分页查询订阅列表-INS:https://yapi.ibaibu.com/project/1398/interface/api/105110
 */
export function getInsSubList(data: Types.ICtInsFindSubscribeByConditionReq) {
  return http.post<Types.ICtInsFindSubscribeByConditionRes>({
    url: '/moken-portal/web/v1/ct-ins/find-subscribe-by-condition',
    server,
    loading: true,
    data,
  });
}

/**
 * 订阅与取消订阅-INS
 */
export function postInsSubscribe(data: Types.IMkSubscribeReq) {
  return http.post<Types.IMkSubscribeRes>({
    url: '/moken-portal/web/v1/mk/subscribe',
    isCancelDuplicateUrlRequests: true,
    server,
    data,
  });
}

/**
 * 分页查询订阅列表-品牌:https://yapi.ibaibu.com/project/1398/interface/api/108454
 */
export function getBrandSubList(data: Types.IBrandFindSubscribeByConditionReq) {
  return http.post<Types.IBrandFindSubscribeByConditionRes>({
    url: '/moken-portal/web/v1/brand/find-subscribe-by-condition',
    server,
    loading: true,
    data,
  });
}

/**
 * 订阅与取消订阅-品牌
 */
export function postBrandSubscribe(data: Types.IBrandSubscribeReq) {
  return http.post<Types.IBrandSubscribeRes>({
    url: '/moken-portal/web/v1/brand/subscribe',
    isCancelDuplicateUrlRequests: true,
    loading: true,
    server,
    data,
  });
}
