import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2985cba0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ellipsis-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "description",
      ref: "description",
      style: _normalizeStyle([_ctx.defaultConcatStyle, _ctx.textStyle])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4),
    (_ctx.ifShowMoreBtn)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "text",
          onClick: _ctx.handleShowMore
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.textVisible==='hidden'?'更多':'收起'), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}