import { numberfuns } from 'cx-utils';

export function useMathFormat() {
  const intValidator = (num: string | number) => {
    let Nnum = Number(num < 0 ? 0 : num);
    // let Nnum = Number(num);
    Nnum = Nnum ?? 0;
    if (Nnum < 10000) return Nnum;
    const Fnum = +numberfuns.formatFloat(Nnum / 10000, { pos: 1, round: true });
    return `${Fnum}w`;
  };
  return {
    intValidator,
  };
}
