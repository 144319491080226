import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';
import { exportByBlob } from '@/core/utils/file-download';

import {
  IGetDesignTasksReq,
  IGetDesignTasksRes,
  ICreateDesignTaskReq,
  IUpdateDesignTasksReq,
  IGetDesignTaskDetailRes,
  IGetDesignTaskOptionsRes,
  IGetQueryOptionsReq,
  IGetQueryOptionsRes,
  IGetImageEsInfoRes,
  IGetDesignTaskTabInfoRes,
  IPurchaserPrivateChooseReq,
  IPurchaserPrivateChooseRes,
  IGetDesignTaskDetailStylesReq,
  IGetDesignTaskDetailStylesRes,
  ICreateDesignTaskDetailStyleReq,
  IGetDesignTaskDetailStyleInfoRes,
  IGetDesignTaskDetailStyleInfoReq,
  IGetDesignTaskDetailStyleCollectorRes,
  IGetDesignTaskDetailStyleCategoryRes,
  IGetIdeaDetailTabCountRes,
  IBatchCollectStyleReq,
  ISetStyleMainCoverReq,
  IBatchAuditStyleReq,
  IUploadStylePictureReq,
  IGetDesignTaskDetailV2Res,
  IGetTaskShareCodeRes,
  ICheckShareCodeRes,
  ICheckShareCodeReq,
  IGetStyleCategoryOpitonsRes,
  ICollectStyleForPluginReq,
  IGetStyleChannelOptionsRes,
  IGetIdeaListReq,
  IGetIdeaListRes,
  ISubmitMadeSampleReq,
  IGetSupplierOptionsRes,
} from './types';

const server = DOMAIN_SYSTEM_ENUM.tiangong;

/**
 * 获取设计任务列表
 */
export function getDesignTasks(data: IGetDesignTasksReq) {
  return http.post<IGetDesignTasksRes>({
    url: '/moken-portal/web/v1/design-task/page',
    loading: true,
    server,
    data,
  });
}

/**
 * 获取想法列表
 */
export function getIdeaList(data: IGetIdeaListReq) {
  return http.post<IGetIdeaListRes>({
    url: '/moken-portal/web/v1/design-task/idea-page',
    loading: true,
    server,
    data,
  });
}

/**
 * 获取设计任务tab
 */
export function getDesignTaskTabInfo() {
  return http.get<IGetDesignTaskTabInfoRes>({
    url: '/moken-portal/web/v1/design-task/tags',
    loading: true,
    server,
  });
}
/**
 * 获取设计任务详情
 */
export function getDesignTaskDetail(params: {
  id: string;
}) {
  return http.get<IGetDesignTaskDetailRes>({
    url: '/moken-portal/web/v1/design-task-detail/find-one-by-id',
    loading: true,
    server,
    params,
  });
}

/**
 * 获取设计任务详情
 */
export function getDesignTaskDetailV2(params: {
  id: string;
}) {
  return http.get<IGetDesignTaskDetailV2Res>({
    url: '/moken-portal/web/v1/design-task/find-one-by-id',
    loading: true,
    server,
    params,
  });
}

/**
 * 获取设计任务下拉选项
 */
export function getDesignTaskOptions() {
  return http.post<IGetDesignTaskOptionsRes[]>({
    url: '/moken-portal/web/v1/design-task/task-name-list',
    server,
  });
}
/**
 * 创建设计任务
 */
export function createDesignTasks(data: ICreateDesignTaskReq) {
  return http.post({
    url: '/moken-portal/web/v1/design-task/save',
    loading: true,
    server,
    data,
  });
}

/**
 * 提交设计任务
 */
export function submitDesignTasks(data: {
  id: string;
}) {
  return http.post({
    url: '/moken-portal/web/v1/design-task/submit-task',
    loading: true,
    server,
    data,
  });
}

/**
 * 确定选图
 */
export function confirmImage(data: {
  id: string;
  selectedImg: string;
  categoryClass1: string;
}) {
  return http.post({
    url: '/moken-portal/web/v1/design-task/image-update',
    loading: true,
    server,
    data,
  });
}

/**
 * 更新设计任务
 */
export function updateDesignTasks(data: IUpdateDesignTasksReq) {
  return http.post({
    url: '/moken-portal/web/v1/design-task/update',
    loading: true,
    server,
    data,
  });
}

/**
 * 获取下拉选项
 */
export function getQueryOptions(data: IGetQueryOptionsReq) {
  return http.post<IGetQueryOptionsRes>({
    url: '/moken-portal/web/v1/design-task/chebox-list',
    isCancelDuplicateUrlRequests: true,
    server,
    data,
  });
}

/**
 * 获取图库图片信息
 */
export function getImageEsInfo(params: {
  keyIndex: string;
}) {
  return http.get<IGetImageEsInfoRes>({
    url: '/moken-portal/web/v1/design-task-detail/find-es-by-id',
    loading: true,
    server,
    params,
  });
}

// 获取设计任务详情的款式列表
export const getDesignTaskDetailStyles = (data: IGetDesignTaskDetailStylesReq) => {
  return http.post<IGetDesignTaskDetailStylesRes[]>({
    url: '/moken-portal/web/v1/design-task-detail/list-idea-style-by-tag',
    data,
    loading: true,
  });
};

// 上传设计任务详情的款式
export const createDesignTaskDetailStyle = (data: ICreateDesignTaskDetailStyleReq) => {
  return http.post({
    url: '/moken-portal/web/v1/design-task-detail/upload-style',
    data,
    loading: true,
    isCancelDuplicateUrlRequests: true,
  });
};

// 删除设计任务详情的款式
export const deleteDesignTaskDetailStyle = (data: string[]) => {
  return http.post({
    url: '/moken-portal/web/v1/design-task-detail/delete-style',
    data,
  });
};

// 获取设计任务详情的款式详情
export const getDesignTaskDetailStyleInfo = (params: IGetDesignTaskDetailStyleInfoReq) => {
  return http.get<IGetDesignTaskDetailStyleInfoRes>({
    url: '/moken-portal/web/v1/design-task-detail/find-style-detail-by-id',
    params,
    loading: true,
  });
};

// 获取设计任务采集人员姓名下拉选择数据接口
export const getDesignTaskDetailStyleCollector = (params: {
  id: string;
}) => {
  return http.get<IGetDesignTaskDetailStyleCollectorRes[]>({
    url: '/moken-portal/web/v1/design-task/collector-name-list',
    params,
    loading: true,
  });
};
// 获取设计任务款式一级品类下拉数据接口 当前可用
export const getDesignTaskDetailStyleCategory = (params: {
  id: string;
}) => {
  return http.get<IGetDesignTaskDetailStyleCategoryRes[]>({
    url: '/moken-portal/web/v1/design-task/category-class-1',
    params,
    loading: true,
  });
};
// 一级品类
export const getStyleCategoryOpitons = () => {
  return http.get<IGetStyleCategoryOpitonsRes[]>({
    url: '/moken-portal/web/v1/style-lib/category-class-1',
    loading: true,
  });
};
/**
 * 想法状态统计接口
 * @see https://yapi.ibaibu.com/project/1398/interface/api/110820
 */
export const getIdeaDetailTabCount = (params: {
  id: string;
}) => {
  return http.get<IGetIdeaDetailTabCountRes[]>({
    url: '/moken-portal/web/v1/design-task/idea-status-tags',
    params,
    loading: true,
  });
};
// 批量采集款式到设计任务
export const batchCollectStyle = (data: IBatchCollectStyleReq) => {
  return http.post({
    url: '/moken-portal/web/v1/design-task/collect-to-task',
    data,
    loading: true,
  });
};

// chrome插件采集
export const collectStyleForPlugin = (data: ICollectStyleForPluginReq) => {
  return http.post({
    url: '/moken-portal/web/v1/design-task-detail/collect-for-plugin',
    data,
    loading: true,
    isCancelDuplicateUrlRequests: true,
  });
};

// 删除款式图片
export const deleteStylePicture = (params: {
  pictureId: string;
}) => {
  return http.post({
    url: '/moken-portal/web/v1/design-style-picture/delete',
    params,
    loading: true,
  });
};

// 设置主图
export const setStyleMainCover = (data: ISetStyleMainCoverReq) => {
  return http.post<boolean>({
    url: '/moken-portal/web/v1/design-task-detail/set-primary-picture',
    data,
    loading: true,
  });
};

// 批量通过、淘汰、待改款式
export const batchAuditStyle = (data: IBatchAuditStyleReq) => {
  return http.post<boolean>({
    url: '/moken-portal/web/v1/design-task-detail/audit-style',
    data,
    loading: true,
  });
};

// 选为款式
export const styleSubmitToCustomer = (params: {
  /**
   * 款式ID
   */
  designTaskDetailId: string;
}) => {
  return http.post<boolean>({
    url: '/moken-portal/web/v1/design-task-detail/select-as-style',
    params,
    loading: true,
  });
};
// 批量选为款式
export const styleBatchSubmitToCustomer = (data: string[]) => {
  return http.post<boolean>({
    url: '/moken-portal/web/v1/design-task-detail/batch-select-as-style',
    data,
    loading: true,
  });
};

// 款式提交云版房
export const styleSubmitToYBF = (params: {
  /**
   * 款式ID
   */
  designTaskDetailId: string;
}) => {
  return http.post<boolean>({
    url: '/moken-portal/web/v1/design-task-detail/submit-to-edition-room',
    params,
    loading: true,
  });
};

// 上传款式图片
export const uploadStylePicture = (data: IUploadStylePictureReq) => {
  return http.post<boolean>({
    url: '/moken-portal/web/v1/design-task-detail/upload-style-picture',
    data,
    loading: true,
  });
};

// 获取设计任务详情分享Code
export const getTaskShareCode = (params: {
  /**
   * 设计任务
   */
  id: string;
}) => {
  return http.post<IGetTaskShareCodeRes>({
    url: '/moken-portal/web/v1/design-task/share',
    params,
    loading: true,
    isCancelDuplicateUrlRequests: true,
  });
};
// 获取设计任务详情分享Code
export const checkShareCode = (data: ICheckShareCodeReq) => {
  return http.post<ICheckShareCodeRes>({
    url: '/moken-portal/web/v1/design-task/validate-share',
    data,
    loading: true,
    isCancelDuplicateUrlRequests: true,
  });
};
// 获取设计任务详情分享Code
export const getStyleChannelOptions = (params: {
  id: string;
}) => {
  return http.get<IGetStyleChannelOptionsRes[]>({
    url: '/moken-portal/web/v1/design-task/style-source',
    params,
    loading: true,
    isCancelDuplicateUrlRequests: true,
  });
};

// 导出款式Excel文件
export const exportStylesToExcel = (data: {
  ids: string[];
  filename: string;
}) => exportByBlob({
  url: '/moken-portal/web/v1/design-task-detail/export-by-id',
  loading: true,
  data: data.ids,
  filename: data.filename,
});

// 导出款式图片
export const exportStylesToZip = (params: {
  designTaskDetailId: string;
  filename: string;
}) => exportByBlob({
  url: '/moken-portal/web/v1/design-task-detail/download-by-id',
  loading: true,
  params,
  method: 'GET',
  filename: params.filename,
});

// 解析图片地址的base64内容
export const getFileBase64 = (data: {
  url: string;
}) => {
  return http.post<string>({
    url: '/moken-portal/file/parse-image',
    data,
    loading: true,
    isCancelDuplicateUrlRequests: true,
    skipDeleteOssDomain: false,
  });
};

// 订制样衣
export const submitMadeSample = (data: ISubmitMadeSampleReq) => {
  return http.post({
    url: '/moken-portal/web/v1/sample-clothes/submit-sample-clothes',
    data,
    loading: true,
    isCancelDuplicateUrlRequests: true,
  });
};

// 供应商筛选接口(用于保存样衣页)
export const getSupplierOptions = () => {
  return http.get<IGetSupplierOptionsRes[]>({
    url: '/moken-portal/web/v1/supplier/find-all-supplier',
    loading: true,
    isCancelDuplicateUrlRequests: true,
  });
};
