import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import * as Types from './type.d';

const server = DOMAIN_SYSTEM_ENUM.tiangong;

/**
 * 确定选图
 */
export function confirmImage(data: Types.IDesignTaskSelectProductPictureReq) {
  return http.post<Types.IDesignTaskSelectProductPictureRes>({
    url: '/moken-portal/web/v1/design-task/select-product-picture',
    loading: true,
    server,
    data,
  });
}
