
import { defineComponent, computed, ref, onMounted, PropType } from 'vue';

export default defineComponent({
  props: {
    // 限制多少行展示
    line: {
      type: Number,
      default: 4,
    },
    // 设置行高，单位为px
    lineHeight: {
      type: Number,
      default: 20,
    },
    // 自定义传入默认行内样式
    defaultStyle: {
      type: Object as PropType<CSSStyleDeclaration>,
      default: () => ({}),
    },
  },
  setup(props) {
    const description = ref<HTMLElement | null>(null);
    const ifShowMoreBtn = ref<boolean>(false);
    const textVisible = ref<'visible'|'hidden'>('hidden');
    const handleShowMore = () => {
      textVisible.value = textVisible.value === 'hidden' ? 'visible' : 'hidden';
    };
    const textStyle = computed(() => {
      if (textVisible.value === 'hidden') {
        return {
          overflow: 'hidden',
        };
      }
      return {
        '-webkit-line-clamp': 'inherit',
        overflow: 'visible',
      };
    });
    const defaultConcatStyle = computed(() => {
      return {
        ...props.defaultStyle,
        lineHeight: `${props.lineHeight}px`,
        '--lineClamp': props.line,
      };
    });
    onMounted(() => {
      const descriptionElem = description.value as HTMLElement;

      if (parseFloat(descriptionElem?.style.lineHeight) * 4 < descriptionElem?.offsetHeight) {
        ifShowMoreBtn.value = true;
        descriptionElem.classList.add('ellipsis');
      }
    });
    return {
      description,
      ifShowMoreBtn,
      textVisible,
      textStyle,
      defaultConcatStyle,
      handleShowMore,
    };
  },
});
