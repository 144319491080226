import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';
import { exportByBlob } from '@/core/utils/file-download';
import {
  QUERY_SEARCH_TYPE,
  QUERY_SEARCH_ATTRIBUTE,
} from '../constant';
import {
  IGetDomesticGoodsReq,
  IGetDomesticGoodsRes,
  IGetSelectOptionsRes,
  IGetDomesticGoodsInfoRes,
  IGetAttributeOptionsRes,
  IGetDataDistributionRes,
  ITrendAnalysisItem,
  IGetRecommendGoodsRes,
} from './type.d';
import { IEnumOption } from '@/modules/information-manage/shop-manage/api/type.d';
import { OPTION_ENUM } from '@/modules/information-manage/shop-manage/constant';
/**
 * 电商商品库 - 列表
 */
export function getDomesticGoods(data: IGetDomesticGoodsReq) {
  return http.post<IGetDomesticGoodsRes>({
    url: '/moken-portal/web/v1/style-lib/search-product',
    // isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}

/**
 * 电商商品库 - 详情
 */
export function getDomesticGoodsDetail(params: { spu: string; }) {
  return http.get<IGetDomesticGoodsInfoRes>({
    url: '/moken-portal/web/v1/style-lib/find-one-by-spu',
    // isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}
/*
 * 电商商品库 - 详情 - 数据分布
 */
export function getDomesticDataDistribution(params: { spu: string; }) {
  return http.get<IGetDataDistributionRes>({
    url: '/moken-portal/web/v1/style-lib/find-data-distribution',
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}
/*
 * 电商商品库 - 详情 - 推荐商品
 */
export function getDomesticRecommendGoods(params: { spu: string; }) {
  return http.get<IGetRecommendGoodsRes>({
    url: '/moken-portal/web/v1/style-lib/find-recommendation-product',
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}
/*
 * 电商商品库 - 详情 - 趋势分析
 */
export function getDomesticTrendAnalysis(params: { spu: string; }) {
  return http.get<ITrendAnalysisItem[]>({
    url: '/moken-portal/web/v1/style-lib/find-trend-analysis',
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}
/**
 * 款式库 - 下拉选项
 */
export function getStyleGoodsInfo(data: {
  channelId: string;
  skc: string;
}) {
  return http.post<IGetDomesticGoodsInfoRes>({
    url: '/moken-portal/web/v1/style-lib/find-one-by-id',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}

/**
 * 款式库 - 下拉选项
 */
export function getSelectOptions(data: {
  skcType: QUERY_SEARCH_TYPE;
}) {
  return http.post<IGetSelectOptionsRes[]>({
    url: `/moken-portal/web/v1/style-lib/style-skc-list/${data.skcType}`,
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}

/**
 * 根据商品SPU下载
 */
export const downloadImageBySpu = (params: {
  spu: string;
  filename: string;
}) => exportByBlob({
  url: '/moken-portal/web/v1/style-lib/download-by-spu',
  loading: true,
  params,
  filename: params.filename,
});

/**
 * SPU采集商品，用于电商商品库选图
 */
export function confirmImageBySpu(data: {
  id: string;
  selectedImg: string;
  categoryClass1: string;
}) {
  return http.post<IGetSelectOptionsRes[]>({
    url: '/moken-portal/web/v1/design-task/spu-image-update',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}

/**
 * 获取商品品类
 */
export function getCategoryOptions() {
  return http.get<IGetSelectOptionsRes[]>({
    url: '/moken-portal/web/v1/style-lib/category',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
  });
}

/**
 * 获取特殊属性
 */
export function getCategoryAttributeOptions(params: {valueCode: string;}) {
  return http.get<IGetSelectOptionsRes[]>({
    url: '/moken-portal/web/v1/style-lib/special',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}

/**
 * 获取属性字典
 */
export function getAttributeOptions(params: {
  dictCode: QUERY_SEARCH_ATTRIBUTE;
}) {
  return http.get<IGetAttributeOptionsRes[]>({
    url: '/moken-portal/web/v1/dict-value/attribute',
    // isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}

/**
 * 获取数仓字典属性:店铺｜品牌名
 */
export function getScDictOptions(params: {
  dictType: OPTION_ENUM;
}) {
  return http.get<IEnumOption[]>({
    url: '/moken-portal/web/v1/intelligence-center/sys-dict/find-by-dict-type',
    // isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}
