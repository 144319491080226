
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  emits: ['update:visible', 'sub-cancel'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // const $router = useRouter();

    const handleClose = async () => {
      emit('update:visible', false);
    };

    const handleConfirm = async () => {
      emit('sub-cancel');
    };

    return {
      handleClose,
      handleConfirm,
    };
  },
  render() {
    return (
      <el-dialog
        title= '取消订阅'
        modelValue={this.visible}
        onClose={this.handleClose}
        top="40vh"
        center
        close-on-click-modal={false}
        close-on-press-escape={false}
        show-close={true}
        width="400px"
        custom-class="el-dialog-inner-scroll"
        v-slots={{
          footer: () => (
            <el-row type="flex" justify="end">
              <el-button size="small" onClick={this.handleClose}>取消</el-button>
              <el-button
                type="primary"
                size="small"
                onClick={this.handleConfirm}
              >确定</el-button>
            </el-row>
          ),
        }}
      >
        <span>是否确定取消订阅？</span>
      </el-dialog>
    );
  },
});
