import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import { exportByBlob } from '@/core/utils/file-download';
import * as Types from './type.d';

const server = DOMAIN_SYSTEM_ENUM.tiangong;
/**
 * 妙品列表
 */
export function getMiaoPinList(data: Types.ICtInsFindInsDetailByPostDateReq) {
  return http.post<Types.ICtInsFindInsDetailByPostDateRes>({
    url: '/moken-portal/web/v1/ct-ins/find-ins-detail-by-postDate',
    // isCancelDuplicateUrlRequests: true,
    server,
    loading: true,
    data,
  });
}

/**
 *
 * 查询妙品详情
 */
export function getMiaoPinDetail(postId: string) {
  return http.post<Types.ICtInsFindInsDetailByPostIdRes>({
    url: `/moken-portal/web/v1/ct-ins/find-ins-detail-by-postId/?postId=${postId}`,
    isCancelDuplicateUrlRequests: true,
    server,
    loading: true,
  });
}

/**
 * 下载妙品图片
 */
export const downloadImgByPostId = (params:Types.ICtInsDownloadByPostIdReq) => exportByBlob({
  url: '/moken-portal/web/v1/ct-ins/download-by-postId',
  loading: true,
  params,
  filename: params.filename,
});
