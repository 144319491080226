export enum SEARCH_BASE_SETTING_ENUM{
  /* 发布时间 */
  PUBLISH_TIME='publishTime',
  /* 我的ins */
  MY_INS='myIns',
  /* 博主名称 */
  PO_NAME='poName',
  /* 订阅 */
  SUB_TYPE='subscribeType',
  /* 品牌-品牌类型 */
  BRAND_TYPE='brandType',
  /* 品牌-地区 */
  BRAND_AREA='brandArea',
  /* 品牌-品牌名称 */
  BRAND_NAME='brandName',
  /* 城市 */
  CITY='city',
  /* 品类 */
  CATEGORY='categoryClass',
  /* 属性 */
  ATTRS='categoryAttr',
  /* 年份季节 */
  YEAR_SEASON='yearSeason',
}

export enum SORT_WORD_ENUM {
  /* 妙品-最新发布 */
  LATEST_PUBLISH = 'latestPostDate',
  /* 博主-最新发布 */
  LATELY = 'lately',
}

export const SORT_WORD_LIST = [
  { value: SORT_WORD_ENUM.LATEST_PUBLISH, label: '最新发布' },
];

// 妙款-排序-最新发布
export const MK_SORT_WORD_LIST = [
  { value: SORT_WORD_ENUM.LATELY, label: '最新发布' },
];

export enum SORT_TYPE_ENUM {
  /* 正序 */
  TOP = 'ascending',
  /* 倒序 */
  DOWN = 'descending',
}

export enum SUB_STATUS_ENUMS {
  /* 订阅 */
  SUB = '1',
  /* 取消订阅 */
  CANCEL='0',
}
//
// export const SUB_STATUS_LIST = [
//   { value: SUB_STATUS_ENUMS.SUB, label: '订阅' },
//   { value: SUB_STATUS_ENUMS.UNSUB, label: '取消订阅' },
// ];

export enum SUB_TYPE_ENUMS {
  /* 我的订阅 */
  MY_SUB = '1',
  /* 团队订阅 */
  TEAM_SUB = '0',
}

export const SUB_TYPE_LIST = [
  { value: SUB_TYPE_ENUMS.MY_SUB, label: '我的订阅' },
  { value: SUB_TYPE_ENUMS.TEAM_SUB, label: '团队订阅' },
];

// 是否我的INS
export enum IS_MY_INS_ENUMS {
  YES = '1',
  NO = '0',
}

// // 是否我的INS
// export enum IS_MY_INS_ENUMS {
//   YES = '1',
//   NO = '0',
// }
