
import { defineComponent, PropType, ref } from 'vue';
import { ElMessage, ElForm } from 'element-plus';
import { confirmImage } from '@/modules/miaokuan-manage/common/api';
import { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import { useRouter } from 'vue-router';
import type { IDesignTaskSelectProductPictureReq } from '@/modules/miaokuan-manage/common/api/type.d';

export default defineComponent({
  components: {
  },
  emits: ['update:visible', 'success'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object as PropType<IDesignTaskSelectProductPictureReq>,
      required: true,
    },
    options: {
      type: Array as PropType<IGetDesignTaskOptionsRes[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $router = useRouter();
    const refForm = ref<InstanceType<typeof ElForm> | null>(null);
    const formData = ref({
      id: '',
    });
    const handleClose = async () => {
      await refForm.value?.resetFields();
      emit('update:visible', false);
    };
    const handleConfirm = async () => {
      try {
        await refForm.value?.validate();
        await confirmImage({ ...props.data, id: formData.value.id });
        localStorage.setItem('quick_design_id', formData.value.id);
        const row = props.options.find(v => v.id === formData.value.id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        emit('success', formData.value.id);
        handleClose();
      } catch (e) {
        console.error(e);
      }
    };
    const handleCreateDesignTask = () => {
      $router.push({
        name: 'DesignTaskManageList',
        params: {
          type: 'create',
        },
      });
    };
    return {
      handleCreateDesignTask,
      formData,
      handleClose,
      rules: {
        id: [
          {
            required: true,
            message: '  ',
          },
        ],
      },
      handleConfirm,
      refForm,
    };
  },
  render() {
    return (
      <el-dialog
        title= '选图'
        modelValue={this.visible}
        onClose={this.handleClose}
        center
        close-on-click-modal={false}
        close-on-press-escape={false}
        show-close={false}
        width="480px"
        custom-class="el-dialog-inner-scroll"
        v-slots={{
          footer: () => (
            <el-row type="flex" justify="end">
              <el-button size="small" onClick={this.handleClose}>取消</el-button>
              <el-button
                type="primary"
                size="small"
                onClick={this.handleConfirm}
              >确定</el-button>
            </el-row>
          ),
        }}
      >
        <el-form
          ref="refForm"
          rules={this.rules}
          model={this.formData}
        >
          <el-form-item
            prop="id"
          >
            <el-select
              v-model={this.formData.id}
              filterable
              class="select-full"
              placeholder="请选择想法"
            >
              {this.options.map(v => (
                <el-option
                  value={v.id}
                  label={v.designTaskName}
                  key={v.id}
                />
              ))}
            </el-select>
          </el-form-item>
          <el-row type="flex" class="tip">
            <div>没有你想要添加的想法？</div>
            <div class="link" onClick={this.handleCreateDesignTask}>创建想法</div>
          </el-row>
        </el-form>
      </el-dialog>
    );
  },
});
