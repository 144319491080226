/*
 * @Author: sharon
 * @Date: 2022-01-07 14:56:20
 * @LastEditTime: 2022-01-14 16:17:45
 * @LastEditors: Please set LastEditors
 * @Description: 选图｜下载图片
 * @FilePath: /miao-kuan/src/modules/miaokuan-manage/components/shopDetail/composables/use-picture-operation.ts
 */
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import slsLogger from '@/core/plugins/slsLogger/SLSLogger';
// import { ICtInsFindInsDetailByPostIdRes } from '@/modules/miaokuan-manage/miaopin/api/type.d';
import { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import { IDesignTaskSelectProductPictureReq } from '@/modules/miaokuan-manage/common/api/type.d';
// mport {IGetDomesticGoodsInfoRes} from '@/modules/resource-manage/domestic-goods/api/type.d'
// import { IGetDomesticGoodsInfoRes } from '@/modules/resource-manage/domestic-goods/api/type.d';
import { downloadZipByChannel } from '@/modules/miaokuan-manage/brand/api';
import { EVENT_TYPE_ENUM } from '@/core/plugins/slsLogger/constant';
import { getDesignTaskOptions } from '@/modules/task-manage/design-task/api';
import { downloadImageBySpu } from '@/modules/resource-manage/domestic-goods/api';
import { confirmImage } from '@/modules/miaokuan-manage/common/api';
import { downloadImgByPostId } from '@/modules/miaokuan-manage/miaopin/api';
import { datefuns } from 'cx-utils';

export const usePictureOperation = () => {
  // 获取想法
  const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
  const getDesignOptions = async () => {
    try {
      const { data } = await getDesignTaskOptions();
      designTaskOptions.value = data;
    } catch (error) {
      console.log(error);
    }
  };
  getDesignOptions();

  // 选图｜快速选图
  const selectImgConfig = ref<{data:IDesignTaskSelectProductPictureReq;visible:boolean;}>(
    {
      data: { id: '', key: '', channelId: '', categoryClass: '' },
      visible: false,
    },
  );
  const handleSelectImgItem = async (
    { key, channelId, category }:{key?: string | undefined; channelId: string; category?: string | undefined;},
    type: 'quick'|'normal' = 'normal') => {
    const id = localStorage.getItem('quick_design_id');
    let eventDetails: { [key: string]: string; } = {
      eventName: '选图',
      goodId: key || '',
    };
    if (type === 'quick') {
      eventDetails = {
        ...eventDetails,
        eventName: '快速选图',
        taskId: id || '',
      };
    }
    if (eventDetails) {
      slsLogger.send({
        eventType: EVENT_TYPE_ENUM.CLICK,
        eventDetails,
      });
      const param: IDesignTaskSelectProductPictureReq = {
        id: id || '',
        key,
        channelId,
        categoryClass: category,
      };
      if (type === 'quick' && id) {
        await confirmImage(param);
        const row = designTaskOptions.value.find(v => v.id === id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        return;
      }
      selectImgConfig.value = { data: param, visible: true };
    }
  };

  // 下载图片-博主
  const idKey = {
    ins: 'postId',
    spu: 'spu',
  };
  const downloadRequest = {
    ins: downloadImgByPostId,
    spu: downloadImageBySpu,
  };
  const handleDownload = async (
    { name, id }:{name: string|undefined; id:string|undefined;},
    type: 'ins'|'spu' = 'spu',
  ) => {
    if (name && id) {
      const params = {
        [idKey[type]]: id,
        filename: `${name}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD')}.zip`,
      };
      try {
        await downloadRequest[type](params as any);
      } catch (error) {
        console.error(error);
      }
    }
    slsLogger.send({
      eventType: EVENT_TYPE_ENUM.CLICK,
      eventDetails: {
        eventName: '下载图片',
        goodId: id || '',
      },
    });
  };
  // 下载图片
  const handleDownloadByChannel = async (
    channelId: string,
    id: string,
  ) => {
    if (channelId && id) {
      const param = {
        channelId,
        id,
      };
      try {
        await downloadZipByChannel(param as any);
      } catch (error) {
        console.error(error);
      }
    }
    slsLogger.send({
      eventType: EVENT_TYPE_ENUM.CLICK,
      eventDetails: {
        eventName: '下载图片',
        goodId: id || '',
      },
    });
  };

  // // 下载图片——淘品
  // const handleDownloadBySpu = async (curInfo: IGetDomesticGoodsInfoRes|null) => {
  //   if (curInfo) {
  //     const { spu = '', spuName = '' } = curInfo;
  //     try {
  //       await downloadImageBySpu({
  //         spu,
  //         filename: `${spuName}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD')}.zip`,
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   slsLogger.send({
  //     eventType: EVENT_TYPE_ENUM.CLICK,
  //     eventDetails: {
  //       eventName: '下载图片',
  //       goodId: curInfo?.spu || '',
  //     },
  //   });
  // };

  // 选图弹窗-确定
  const handleSelectSuccess = (id: string) => {
    slsLogger.send({
      eventType: EVENT_TYPE_ENUM.CLICK,
      eventDetails: {
        eventName: '选图弹窗-点击确定',
        goodId: selectImgConfig.value.data.key,
        taskId: id,
      },
    });
  };

  return {
    designTaskOptions,
    selectImgConfig,
    handleSelectImgItem,
    handleDownload,
    handleSelectSuccess,
    handleDownloadByChannel,
  };
};
