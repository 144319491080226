
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import IconSpeed from '@/assets/images/home/icon_speed.png';
import IconStyle from '@/assets/images/icon_style_title.png';
import IconLeftArrow from '@/assets/images/arrow_left.png';
import IconRightArrow from '@/assets/images/arrow_right.png';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import { ElMessage } from 'element-plus';
import { getMiaoPinDetail } from '@/modules/miaokuan-manage/miaopin/api';
import { ICtInsFindInsDetailByPostIdRes } from '@/modules/miaokuan-manage/miaopin/api/type.d';
import { usePictureOperation } from '../composables/use-picture-operation';
import SelectImgDialog from '@/modules/miaokuan-manage/common/components/select-img-dialog/index.vue';
import DescriptionCard from './description-card.vue';

export default defineComponent({
  name: 'ins',
  components: {
    Swiper,
    SwiperSlide,
    SelectImgDialog,
    DescriptionCard,
  },
  emits: ['select-img'],
  setup() {
    const router = useRouter();
    const handleRouteToList = () => {
      router.push({
        name: 'BrandList',
      });
    };
    const route = useRoute();
    // 轮播灯箱图选中项index
    const currentIndex = ref(0);
    // 轮播灯箱组件
    const swiperEl = ref<any>(null);
    // 详细信息
    const info = ref<ICtInsFindInsDetailByPostIdRes|null>(null);

    // 获取页面详细信息
    const getInfo = async () => {
      currentIndex.value = 0;
      info.value = {} as ICtInsFindInsDetailByPostIdRes;
      const postId = route.query?.id as string;
      const { data } = await getMiaoPinDetail(postId);
      if (data) {
        info.value = data;
      } else {
        ElMessage.error('商品不存在');
      }
    };

    const handleItem = (index: number) => {
      if (index !== currentIndex.value) {
        currentIndex.value = index;
      }
    };
    const handleNext = () => {
      if (!info.value) return;
      if (currentIndex.value < info.value.pictureList.length - 1) {
        currentIndex.value += 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };
    const handlePrev = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };

    // 选图和下载图片
    const {
      designTaskOptions,
      selectImgConfig,
      handleSelectImgItem,
      handleDownload,
      handleSelectSuccess,
    } = usePictureOperation();

    const handleLink = (url: string) => {
      window.open(url);
    };

    // 重置灯箱图滚动器位置
    const initSwiper = (el: any) => {
      swiperEl.value = el;
    };

    const init = () => {
      getInfo();
    };
    init();

    return {
      info,
      designTaskOptions,
      selectImgConfig,
      handleSelectImgItem,
      handleDownload,
      handleSelectSuccess,
      handleLink,
      initSwiper,
      handleNext,
      handlePrev,
      handleItem,
      modules: [Navigation],
      currentIndex,
      getInfo,
      handleRouteToList,
    };
  },
  render() {
    return (
      <div>
        <div class="breadcrumb">
          <span>妙款 / </span>
          <span style="cursor: pointer;" onClick={this.handleRouteToList}>品牌 /</span>
          <span class="breadcrumb_active"> 商品详情</span>
        </div>
        {this.info?.postId && (
          <div class="container">
            <div class="header">
              <div class="header-left">
                <div class="avator">
                  <custom-image
                    style="width: 72px; height: 72px;border-radius: 40px"
                    src={this.info.poLogoUrl}
                    fit="cover"
                  ></custom-image>
                </div>
                <div class="name">{this.info.poName}</div>
                <i class="icon"></i>
              </div>
              <div class="picture-operation">
                <div class="btns">
                  <div class="select choose-image"
                  onClick={() => this.handleSelectImgItem({
                    channelId: this.info?.channelId as string,
                    key: this.info?.postId,
                  })}>选图</div>
                  <el-tooltip content="添加到最近一次选图分组" placement="bottom-start">
                    <div
                      class="quick"
                      onClick={() => this.handleSelectImgItem({
                        channelId: this.info?.channelId as string,
                        key: this.info?.postId,
                      }, 'quick')}
                    >
                      <img src={IconSpeed} />
                    </div>
                  </el-tooltip>
                </div>
                <div class="btns">
                  <el-button className="select download-btn"
                              onClick={() => this.handleDownload({
                                name: this.info?.poName,
                                id: this.info?.postId,
                              }, 'ins')}
                  >
                    下载图片
                  </el-button>
                </div>
              </div>
            </div>
            <section class="main">
              <div class="cover_layout">
                <div class="main-title">
                  <div class="title-left flex">
                    <img src={IconStyle} class="icon"/>
                    <div>妙品详情</div>
                  </div>
                </div>
                <custom-image
                  hide-on-click-modal={true}
                  src={this.info.pictureList[this.currentIndex]}
                  fit="cover"
                  class="main_cover"
                  initial-index={this.currentIndex}
                  preview-src-list={this.info.pictureList}
                />
                <div class="theme_layout">
                  <div class={{
                    btn: true,
                    prev: true,
                    disabled: this.currentIndex === 0,
                  }} onClick={this.handlePrev}>
                    <img src={IconLeftArrow} class="icon" />
                  </div>
                  <swiper
                    slides-per-view={5}
                    space-between={12}
                    slideToClickedSlide={true}
                    grabCursor={true}
                    centeredSlides={false}
                    onSwiper={this.initSwiper}
                  >
                    {this.info.pictureList.map((v:any, i:any) => (
                      <swiper-slide
                        key={i}
                        class="item"
                        v-slots={{
                          default: () => (
                            <div
                              class={{
                                cover: true,
                                active: this.currentIndex === i,
                              }}
                              onClick={() => this.handleItem(i)}
                            >
                              <img
                                src={v}
                                alt=""
                                class="img"
                              />
                            </div>
                          ),
                        }}
                      />
                    ))}
                  </swiper>
                  <div class={{
                    btn: true,
                    next: true,
                    disabled: this.currentIndex >= this.info.pictureList.length - 1,
                  }} onClick={this.handleNext}>
                    <img src={IconRightArrow} class="icon" />
                  </div>
                </div>
              </div>
              <div class="desc-info">
                <description-card
                  data={this.info}
                  onRefresh={this.getInfo}
                />
              </div>
            </section>
            <select-img-dialog
              v-model={[this.selectImgConfig.visible, 'visible']}
              data={this.selectImgConfig.data}
              options={this.designTaskOptions}
              onSuccess={this.handleSelectSuccess}
            />
          </div>
        )}
      </div>
    );
  },
});
