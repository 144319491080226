import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import * as Types from './type.d';
import { QUERY_SEARCH_TYPE } from '@/modules/miaokuan-manage/brand/constant';

const server = DOMAIN_SYSTEM_ENUM.tiangong;
/**
 * 品牌-公共字典查询
 */
export function getSelectOptions(params:Types.IDictValueGetByDictCodeReq) {
  return http.get<Types.IDictValueGetByDictCodeRes>({
    url: '/moken-portal/web/v1/dict-value/get-by-dict-code',
    params,
    isCancelDuplicateUrlRequests: false,
    server,
    loading: true,
  });
}
/**
 * 品牌-获取所有品牌名称
 */
export function getBrandOptions() {
  return http.get<Types.IBrandFindAllBrandRes>({
    url: '/moken-portal/web/v1/brand/find-all-brand',
    // params,
    isCancelDuplicateUrlRequests: false,
    server,
    loading: true,
  });
}

/**
 * 获取品牌列表
 */
export function getBrandList(data:Types.IBrandBrandListPageReq) {
  return http.post<Types.IBrandBrandListPageRes>({
    url: '/moken-portal/web/v1/brand/brand-list-page',
    server,
    loading: true,
    data,
  });
}

/**
 * 品牌-获取品牌秀场城市
 */
export function getShowCityOptions(params:Types.IBrandShowCityReq) {
  return http.get<Types.IBrandShowCityRes>({
    url: '/moken-portal/web/v1/brand/show-city',
    params,
    isCancelDuplicateUrlRequests: false,
    server,
    loading: true,
  });
}

/**
 * 获取属性字典
 */
export function getAttributeOptions(params: {
  dictCode: QUERY_SEARCH_TYPE;
}) {
  return http.get<Types.IGetAttributeOptionsRes[]>({
    url: '/moken-portal/web/v1/dict-value/attribute',
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}

/**
 * 获取商品品类
 */
export function getCategoryOptions() {
  return http.get<Types.IGetSelectOptionsRes>({
    url: '/moken-portal/web/v1/style-lib/category',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
  });
}

/**
 * 获取特殊属性
 */
export function getCategoryAttributeOptions(params: {valueCode: string;}) {
  return http.get<Types.IGetSelectOptionsRes[]>({
    url: '/moken-portal/web/v1/style-lib/special',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    params,
  });
}

/**
 * 品牌详情列表查询
 */
export function getBrandDetailList(data:Types.IBrandBrandDetailPageReq) {
  return http.post<Types.IBrandBrandDetailPageRes>({
    url: '/moken-portal/web/v1/brand/brand-detail-page',
    loading: true,
    server,
    data,
  });
}

/**
 * 获取品牌详情信息：https://yapi.ibaibu.com/project/1398/interface/api/108458
 */
export function getBrandDetailInfo(data:Types.IBrandFindMkbrandDetailReq) {
  return http.get<Types.IBrandFindMkbrandDetailRes>({
    url: '/moken-portal/web/v1/brand/find-mkbrand-detail',
    server,
    // loading: true,
    params: data,
  });
}

/**
 * 各渠道图片下载ZIP接口：https://yapi.ibaibu.com/project/1398/interface/api/107918
 */
export function downloadZipByChannel(data:Types.IPictureDownloadZipReq) {
  return http.post<Types.IPictureDownloadZipRes>({
    url: '/moken-portal/web/v1/picture/download-zip',
    server,
    // loading: true,
    data,
  });
}
