
import { defineComponent, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import { SUB_STATUS_ENUMS } from '@/modules/miaokuan-manage/common/constant';
import CancelSub from '@/modules/miaokuan-manage/common/components/cancel-sub/index.vue';
import EllipsisText from '@/modules/miaokuan-manage/common/components/ellipsis-text/index.vue';
import { postInsSubscribe } from '@/modules/miaokuan-manage/sub/api';
import { IMkSubscribeReq } from '@/modules/miaokuan-manage/sub/api/type.d';
import { ElMessage } from 'element-plus';
import { ICtInsFindInsDetailByPostIdRes } from '@/modules/miaokuan-manage/miaopin/api/type.d';
import { useMathFormat } from '@/modules/miaokuan-manage/common/composables/use-math-format';
import dayjs from 'dayjs';
import slsLogger from '@/core/plugins/slsLogger/SLSLogger';
import { EVENT_TYPE_ENUM } from '@/core/plugins/slsLogger/constant';

export default defineComponent({
  emits: ['refresh'],
  props: {
    data: {
      type: Object as PropType<ICtInsFindInsDetailByPostIdRes>,
      default: () => ({}),
    },
  },
  components: { CancelSub, EllipsisText },
  setup(props) {
    const { intValidator } = useMathFormat();
    const isSub = ref<boolean>(props.data.alreadySubscribe);

    const router = useRouter();

    const viewBloggerDtl = (poId:string) => {
      const newPage = router.resolve({
        name: 'BloggerDetail',
        query: { poId },
      });
      window.open(newPage.href);
      slsLogger.send({
        eventType: EVENT_TYPE_ENUM.CLICK,
        eventDetails: {
          eventName: '下载图片',
          targetId: poId,
        },
      });
    };

    // 打开取消订阅弹框
    const cancelSubConfig = ref<{data:any;visible:boolean;}>(
      { visible: false, data: [] },
    );
    const openCancelSub = () => {
      cancelSubConfig.value.visible = true;
      slsLogger.send({
        eventType: EVENT_TYPE_ENUM.CLICK,
        eventDetails: {
          eventName: '取消订阅',
          targetId: props.data.poId,
        },
      });
    };
    // 订阅｜取消订阅
    const handleSubToggle = async (isSubscribe:SUB_STATUS_ENUMS = SUB_STATUS_ENUMS.SUB, eventName: string) => {
      slsLogger.send({
        eventType: EVENT_TYPE_ENUM.CLICK,
        eventDetails: {
          eventName,
          targetId: props.data.poId,
        },
      });
      const param:IMkSubscribeReq = {
        poId: props.data.poId,
        isSubscribe,
      };
      await postInsSubscribe(param);
      const msg = isSubscribe === SUB_STATUS_ENUMS.SUB ? '订阅成功' : '取消订阅成功';
      ElMessage.success(msg);
      isSub.value = isSubscribe !== SUB_STATUS_ENUMS.CANCEL;
      cancelSubConfig.value.visible = false;
    };

    return {
      dayjs,
      intValidator,
      openCancelSub,
      handleSubToggle,
      cancelSubConfig,
      SUB_STATUS_ENUMS,
      viewBloggerDtl,
      isSub,
    };
  },
});
